import { DefaultDataStoreName, } from "@/config.js";

import { v4 as uuidv4 } from "uuid";
import localforage from "localforage";

const DataStore = {
    namespaced: true,
    state: {
        store: null,
        currentStore: "",
        stores: [],

        taskStages: [],
        tasks: [],
        tasklets: [],
        attachments: [],

        users: [],
        notifications: [],
    },
    getters: {
        currentStore ({ currentStore }) {
            return currentStore;
        },
        stores ({ stores }) {
            return stores;
        },

        taskStages ({ taskStages }) {
            return taskStages;
        },
        taskStageById ({ taskStages }) {
            return stageId => taskStages.find(({ id }) => id === stageId);
        },
        tasks ({ tasks }) {
            return tasks;
        },
        tasksByStage ({ tasks }) {
            return stageId => tasks.filter(({ stage }) => stage === stageId);
        },
        taskTotalByStage ({ tasks }) {
            return stageId => tasks.filter(({ stage }) => stage === stageId).length;
        },
        taskById ({ tasks }) {
            return taskId => tasks.find(({ id }) => id === taskId);
        },
        tasklets ({ tasklets }) {
            return tasklets;
        },
        taskletsByTaskId ({ tasklets }) {
            return taskId => tasklets.filter(tasklet => tasklet.taskId === taskId);
        },
        attachmentsByTaskId ({ attachments }) {
            return taskId => attachments.filter(attachment => attachment.taskId === taskId);
        },

        users ({ users }) {
            return users;
        },
        userById ({ users }) {
            return userId => users.find(({ id }) => id === userId);
        },

        notifications ({ notifications }) {
            return notifications;
        },
    },
    mutations: {
        setStore (state, store) {
            state.store = store;
        },
        setCurrentStore (state, name) {
            state.currentStore = name;
        },
        setStores (state, stores) {
            state.stores = stores;
        },

        setTaskStages (state, stages) {
            state.taskStages = stages;
        },
        addTaskStage (state, stage) {
            state.taskStages.push({ "id": uuidv4(), ...stage });
            state.store.setItem("taskStages", state.taskStages);
        },
        editTaskStage (state, stage) {
            state.taskStages.splice(
                state.taskStages.findIndex(({ id }) => id === stage.id),
                1,
                stage
            );
            state.store.setItem("taskStages", state.taskStages);
        },
        deleteTaskStage (state, stageId) {
            state.taskStages.splice(
                state.taskStages.findIndex(({ id }) => id === stageId),
                1,
            );
            state.store.setItem("taskStages", state.taskStages);
        },

        setTasks (state, tasks) {
            state.tasks = tasks;
        },
        addTask (state, task) {
            state.tasks.push({ "id": uuidv4(), "createdAt": new Date(), ...task });
            state.store.setItem("tasks", state.tasks);
        },
        editTask (state, task) {
            const target = state.tasks.findIndex(({ id }) => id === task.id);
            state.tasks.splice(
                target,
                1,
                { ...state.tasks[target], ...task }
            );
            state.store.setItem("tasks", state.tasks);
        },
        deleteTask (state, clientId) {
            state.tasks.splice(state.tasks.findIndex(({ id }) => id === clientId), 1);
            state.store.setItem("tasks", state.tasks);
        },
        setTasklets (state, tasklets) {
            state.tasklets = tasklets;
        },
        addTasklet (state, tasklet) {
            state.tasklets.push({ "id": uuidv4(), "createdAt": new Date(), "updatedAt": new Date(), ...tasklet });
            state.store.setItem("tasklets", state.tasklets);
        },
        editTasklet (state, tasklet) {
            const target = state.tasklets.findIndex(({ id }) => id === tasklet.id);
            state.tasklets.splice(
                target,
                1,
                { ...state.tasklets[target], ...tasklet, "updatedAt": new Date(), "updatedBy": "" }
            );
            state.store.setItem("tasklets", state.tasklets);
        },
        setAttachments (state, attachments) {
            state.attachments = attachments;
        },
        addAttachment (state, attachment) {
            state.attachments.push({ "id": uuidv4(), "createdAt": new Date(), "updatedAt": new Date(), ...attachment });
            state.store.setItem("attachments", state.attachments);
        },
        deleteAttachment (state, attachmentId) {
            state.attachments.splice(state.tasks.findIndex(({ id }) => id === attachmentId), 1);
            state.store.setItem("attachments", state.attachments);
        },

        setUsers (state, users) {
            state.users = users;
        },
        addUser (state, user) {
            state.users.push({ "id": uuidv4(), "createdAt": new Date(), ...user,  });
            state.store.setItem("users", state.users);
        },
        editUser (state, user) {
            state.users.splice(
                state.users.findIndex(({ id }) => id === user.id),
                1,
                user
            );
            state.store.setItem("users", state.users);
        },
        deleteUser (state, userId) {
            state.users.splice(
                state.users.findIndex(({ id }) => id === userId),
                1,
            );
            state.store.setItem("users", state.users);
        },

        setNotifications (state, notifications) {
            state.notifications = notifications;
        },
        addNotification (state, notification) {
            state.notifications.push({ "id": uuidv4(), ...notification });
            state.store.setItem("notifications", state.notifications);
        },
        editNotification (state, notification) {
            state.notifications.splice(
                state.notifications.findIndex(({ id }) => id === notification.id),
                1,
                notification
            );
            state.store.setItem("notifications", state.notifications);
        },
        deleteNotification (state, notificationId) {
            state.notifications.splice(
                state.notifications.findIndex(({ id }) => id === notificationId),
                1,
            );
            state.store.setItem("notifications", state.notifications);
        },
    },
    actions: {
        async open ({ commit }, storeName = DefaultDataStoreName) {
            const directory = localforage.createInstance({
                "name": "project-man-store-directory"
            });
            const stores = await directory.getItem("list") ?? [];
            if (stores.findIndex(({ name }) => name === storeName) === -1) {
                stores.push({ "name": storeName });
            }
            await directory.setItem("list", stores);
            commit("setStores", stores);
            commit("setCurrentStore", storeName);

            const store = localforage.createInstance({
                "name": storeName
            });
            commit("setStore", store);

            commit("setTaskStages", await store.getItem("taskStages") ?? []);
            commit("setTasks", await store.getItem("tasks") ?? []);
            commit("setTasklets", await store.getItem("tasklets") ?? []);
            commit("setAttachments", await store.getItem("attachments") ?? []);
            commit("setUsers", await store.getItem("users") ?? []);
            commit("setNotifications", await store.getItem("notifications") ?? []);
        },
    },
};

export default DataStore;
