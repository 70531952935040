import Vue from "vue";

import App from "./App.vue";
import "./registerServiceWorker.js";

import router from "./router/index.js";
import store from "./stores/index.js";

Vue.config.productionTip = false;

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
Sentry.init({
    Vue,
    "dsn": "https://ff58ea7d3842490fbbab46a1885274da@o474560.ingest.sentry.io/6033740",
    "integrations": [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "tinfung.kanbanala.work", /^\//],
        }),
    ],
    "tracesSampleRate": 1.0,
    "trackComponents": true,
    "logErrors": true,
});

import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

import "vue-swatches/dist/vue-swatches.css";

import "./init/font-awesome.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

import { capitalize, } from "./plugins/filters";
Vue.use(capitalize);

import Meta from "vue-meta";
Vue.use(Meta);

const app = new Vue({
    router,
    store,
    render: h => h(App)
});
app.$mount("#app");
