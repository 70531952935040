export const BaseUrl = "https://tinfung.kanbanala.work";
export const apiUrl = "https://tinfung.kanbanala.work/api";
export const wsUrl = "wss://tinfung.kanbanala.work/api";

export const apiVersion = "v1.0";

export const TokenName = "kanbanala:tinfung.user-token";

export const FacebookAppId = "";
export const GoogleMapKey = "AIzaSyBCERdmkUPU3LSwOAiy52-a-mmddaXzh3A";
export const GoogleClientId = "";
export const reCAPTCHAKey = "6LfCBsQUAAAAAHDYo3yjh2IMvi2BVlqgd9LCgghn";

export const RoleMap = {
    "admin": "Administrator",
    "user": "User",
};

export const NewBusinessStatusMap = {
    "PENDING": "Application Pending",
    "CANCELLED": "Application Cancelled",
    "DECLINED": "Application Declined",
    "POSTPONED": "Application Postponed",
    "IN-FORCE": "Policy in Force",
};

export const DefaultDataStoreName = "default-store";

export const DefaultLogin = {
    username: "tester@mmccpa.work",
    password: "wm1BmUyx94buDEODuZPc0ww39SS116",
};

export const DefaultUser = {
    avatar: "",
    company: "ACME Limited",
    username: "Tester Project Management",
    role: "admin",
    email: "tester@mmccpa.work",
    countryCode: "+852",
    phone: "23456789",
    colleagues: [],
    signatures: [],
};
