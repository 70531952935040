<template>
    <header class="navbar-wrapper position-sticky">
        <b-collapse v-model="isMenuOpen">
            <ul class="list-group list-group-flush rounded-0">
                <router-link class="list-group-item list-group-item-action"
                             active-class="active"
                             to="/users"
                             v-if="['admin', ].includes(userRole)">
                    👥 Users
                </router-link>
                <router-link class="list-group-item list-group-item-action"
                             exact-active-class="active"
                             to="/categories"
                             v-if="['admin', ].includes(userRole)">
                    🏷️ Categories
                </router-link>
                <router-link class="list-group-item list-group-item-action"
                             active-class="active"
                             to="/tasks">
                    💼 Tasks
                </router-link>
                <router-link class="list-group-item list-group-item-action"
                             active-class="active"
                             to="/tasks">
                    💽 Drive
                </router-link>
                <button class="list-group-item list-group-item-action"
                        v-on:click="showAccountModal = true">
                    ⚙️ Account
                </button>
            </ul>
        </b-collapse>
        <nav class="navbar navbar-expand-lg navbar-transparent d-flex">
            <router-link class="navbar-brand m-0"
                         to="/">
                <h1 class="h3 text-light font-abril-fatface text-shadow-sm mb-0">
                    🎯 <span class="font-weight-bolder">MMC CPA</span>
                </h1>
            </router-link>
            <div class="flex-grow-1 d-flex justify-content-start align-items-center"></div>
            <div class="flex-grow-1 d-flex justify-content-end align-items-center">
                <router-link class="d-none d-lg-inline-block btn btn-light shadow-sm rounded-pill px-3 py-1 mr-2"
                             active-class="active"
                             to="/users"
                             v-if="['admin', ].includes(userRole)">
                    👥 Users
                </router-link>
                <router-link class="d-none d-lg-inline-block btn btn-light shadow-sm rounded-pill px-3 py-1 mr-2"
                             exact-active-class="active"
                             to="/categories"
                             v-if="['admin', ].includes(userRole)">
                    🏷️ Categories
                </router-link>
                <router-link class="position-relative d-none d-lg-inline-block btn btn-light shadow-sm rounded-pill px-3 py-1 mr-2"
                             active-class="active"
                             to="/tasks">
                    <div class="position-absolute badge-wrapper">
                        <h1 class="h6 mb-0">
                            <span class="badge badge-pill badge-primary font-weight-normal shadow-sm">
                                ⏳ <span class="font-weight-bolder">{{ taskTotal }}</span>
                            </span>
                        </h1>
                    </div>
                    💼 Tasks
                </router-link>
                <router-link class="d-none d-lg-inline-block btn btn-light shadow-sm rounded-pill px-3 py-1 mr-2"
                             exact-active-class="active"
                             to="/categories">
                    💽 Drive
                </router-link>
                <button class="d-none d-lg-inline-block btn btn-light shadow-sm rounded-pill px-3 py-1"
                        v-on:click="showAccountModal = true">
                    ⚙️ Account
                </button>

                <button class="d-lg-none btn btn-light shadow-sm rounded-pill py-1"
                        v-on:click="isMenuOpen = !isMenuOpen">
                    <font-awesome-icon class="mr-2"
                                       v-bind:icon="['fad', 'bars']">
                    </font-awesome-icon>
                    Menu
                </button>
            </div>
        </nav>
        <modal-account v-model="showAccountModal"></modal-account>
    </header>
</template>

<script>
import { BCollapse, } from "bootstrap-vue";
import ModalAccount from "./ModalAccount";
export default {
    name: "NavBar",
    components: {
        BCollapse,
        ModalAccount,
    },
    props: {
        isTransparent: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            isMenuOpen: false,

            previousRoute: null,

            menuCollapsed: true,
            showChangeCityModal: false,
            showLoginModal: false,
            showAccountModal: false,
            showListingModal: false
        }
    },
    computed: {
        userStatus () {
            return this.$store.getters["user/status"];
        },
        userRole () {
            return this.$store.getters["user/role"];
        },
        taskTotal () {
            return this.$store.getters["data/taskStages"]
                .slice(0, -1)
                .reduce(
                    (acc, { id, }) => acc += this.$store.getters["data/taskTotalByStage"](id) ?? 0, 0
                );
        },
    },
    methods: {
        goBack () {
            if (this.previousRoute) {
                this.$router.push({
                    name: this.previousRoute.name
                })
            } else {
                this.$router.push({
                    name: "search-bar"
                })
            }
        },
    },
    mounted () {

    },
    watch: {
        "$route" (newVal, oldVal) {
            this.previousRoute = oldVal
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.navbar-wrapper {
    top: 0;
    left: 0;
    right: 0;
    z-index: 30;

    .navbar {

    }

    .nav-link {
        cursor: pointer;
    }
}

.navbar-collapse {
    transition: max-height 500ms ease-in-out;
    max-height: 1000px;
    overflow: hidden;

    &.collapsed {
        max-height: 0;
    }

    @include media-breakpoint-up(lg) {
        overflow: visible;
    }
}

.navbar-brand {
    cursor: pointer;

    .logo {
        height: 50px;
        width: auto;

        transform: scale(1.1);

        & > svg {
            height: 100%;
            width: auto;

            & *:not(.drawing) {
                opacity: 0;
                transition: opacity 200ms ease-in;
            }

            & *.drawing {
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                animation: dash 1250ms linear forwards;
            }

            &.animated *:not(.drawing) {
                opacity: 1;
            }
        }
    }
}

.badge-wrapper {
    top: -37.5%;
    right: -7.5%;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}
</style>

<style lang="scss">
.navbar {
    transition:
        background-color 750ms ease-in-out,
        box-shadow 750ms ease-in-out;

    &.navbar-transparent {
        background-color: transparent !important;
        box-shadow: none;

        .navbar-toggler {
            border: solid 1px rgba(255, 255, 255, 1);
            color: rgba(255, 255, 255, 1);
        }

        .navbar-nav {
            .nav-item {
                & > button.btn:not(:hover) {
                    background-color: rgba(255, 255, 255, 1);
                }

                & > .nav-link {
                    color: rgba(255, 255, 255, 1);
                    font-weight: bold;
                    text-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
                    transition: background-color 200ms ease;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.35);
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}
</style>
