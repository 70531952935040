import Vue from "vue";
import VueRouter from "vue-router";

import stores from "@/stores/index.js";

import Default from "@/layout/Default.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        component: () => import("@/layout/Login.vue"),
    },
    {
        path: "/",
        component: Default,
        beforeEnter (to, from, next) {
            if (stores.getters["user/status"] !== "loggedIn") {
                const route = {
                    "path": "/login",
                    "query": {
                        ...to.query,
                        to: to.path,
                    }
                };
                next(route);
                return;
            }
            next();
        },
        children: [
            {
                path: "",
                component: () => import("@/views/Home.vue"),
                children: [
                    {
                        path: "",
                        name: "Overview",
                        component: () => import("@/views/Overview.vue"),
                    },
                    {
                        path: "users",
                        component: () => import("@/views/Users.vue"),
                        children: [
                            {
                                path: "",
                                component: () => import("@/components/Users/UserPlaceholder.vue"),
                            },
                            {
                                path: ":userId",
                                component: () => import("@/components/Users/UserDetails.vue"),
                                props: true
                            },
                        ],
                    },
                    {
                        path: "categories",
                        component: () => import("@/views/Categories.vue"),
                    },
                    {
                        path: "tasks",
                        component: () => import("@/views/Tasks.vue"),
                        children: [
                            {
                                path: "",
                            },
                            {
                                path: ":taskId",
                            },
                        ],
                    },
                ]
            },
        ]
    },
];

const router = new VueRouter({
    "mode": "history",
    "base": process.env.BASE_URL,
    routes,
});

export default router;
