<template>
    <div id="app"
         class="bg-blur">
        <router-view></router-view>

        <b-toast no-auto-hide
                 solid
                 v-model="updateAvailable">
            <template v-slot:toast-title>
                <strong>
                    Update available
                </strong>
            </template>
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    Click to update 👉
                </div>
                <div class="d-flex align-items-center">
                    <button class="btn btn-sm btn-primary px-4"
                            v-on:click="updateApp">
                        Reload
                    </button>
                </div>
            </div>
        </b-toast>

        <b-toast no-auto-hide
                 solid
                 v-model="offline">
            <template v-slot:toast-title>
                <strong>Site offline</strong>
            </template>
            The site is now offline. Information may become stale.
        </b-toast>

        <modal-set-password v-bind:token="token"
                            v-model="isModalSetPasswordOpen">
        </modal-set-password>
    </div>
</template>

<script>
import { BToast, } from "bootstrap-vue";
import ModalSetPassword from "./components/modals/ModalSetPassword.vue";
export default {
    metaInfo: {
        titleTemplate: "%s | MMC CPA",
    },
    components: {
        BToast,
        ModalSetPassword,
    },
    data () {
        return {
            isModalSetPasswordOpen: false,
            token: "",
        };
    },
    computed: {
        userStatus () {
            return this.$store.getters["user/status"];
        },
        userRole () {
            return this.$store.getters["user/role"];
        },
        offline () {
            return this.$store.getters["layout/isOffline"];
        },
        updateAvailable () {
            return this.$store.getters["layout/isUpdateAvailable"];
        },
    },
    methods: {
        updateApp () {
            if (navigator.serviceWorker) {
                if (this.$store.getters["layout/serviceWorker"]) {
                    let refreshing = false;
                    navigator.serviceWorker.addEventListener(
                        "controllerchange",
                        () => {
                            if (refreshing) {
                                return;
                            }
                            window.location.reload();
                            refreshing = true;
                        }
                    );
                    this.$store.getters["layout/serviceWorker"].postMessage({ "type": "SKIP_WAITING" });
                }
            }
        },
    },
    async created () {
        await this.$store.dispatch("data/open");
        await this.$store.dispatch("user/init");
        if (this.userStatus === "loggedIn") {
            const route = {
                "path": this.$route.query.to ? this.$route.query.to : "/",
                "query": Object.assign({}, this.$route.query, { "to": undefined }),
            };
            this.$router.push(route);
        }
    },
    mounted () {
        if (
            this.$route.query.setPassword === "true" &&
            this.$route.query.token !== undefined
        ) {
            this.isModalSetPasswordOpen = true;
            this.token = this.$route.query.token;
        }

        window.addEventListener("online", () => this.$store.commit("layout/online"));
        window.addEventListener("offline", () => this.$store.commit("layout/offline"));
    },
    watch: {

    },
}
</script>

<style lang="scss">
$enable-gradients: true;
$theme-colors: (
    "primary": rgba(19, 92, 122, 1),
);
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/src/index";

@import "~vue-select/src/scss/vue-select.scss";

html,
body {
    position: relative;
    //height: 100%;

    background-color: rgba(245, 245, 245, 1);
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        "Microsoft JhengHei",
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color Emoji;
    overflow-x: hidden;

    @include media-breakpoint-down(md) {
        overflow-y: hidden;
    }
}

.bg-blur {
    background-image: url("./assets/bg.jpg");
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
        content: "";

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        background-color: rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(25px) saturate(150%);
        z-index: 0;
    }
}

.font-abril-fatface {
    font-family: "Abril Fatface", cursive;
}

.text-shadow-sm {
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 1);
}

.rounded-xl {
    border-radius: 1rem;
}

.btn {
    &.btn-loading {
        position: relative;
        pointer-events: none;
        color: transparent !important;

        &:after {
            position: absolute;
            left: calc(50% - (1em / 2));
            top: calc(50% - (1em / 2));

            display: block;
            height: 1em;
            width: 1em;

            border: 2px solid;
            border-color: rgba(219, 219, 219, 1);
            border-radius: 290486px;
            border-right-color: transparent;
            border-top-color: transparent;

            content: "";

            animation: spinAround .5s infinite linear;
        }
    }
}

@keyframes spinAround {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}
</style>
