import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faUserCog as faUserCogSolid,
    faPlusSquare as faPlusSquareSolid,
    faCaretDown as faCaretDownSolid,
    faArrowLeft as faArrowLeftSolid,

    faEdit as faEditSolid,
    faTrashAlt as faTrashAltSolid,
    faSpinner as faSpinnerSolid,
    faUserTie as faUserTieSolid,
    faTags as faTagsSolid,
    faSave as faSaveSolid,
    faUserHardHat as faUserHardHatSolid,
    faUpload as faUploadSolid,
    faTasks as faTasksSolid,
    faImage as faImageSolid,
    faFileAlt as faFileAltSolid,
    faTimes as faTimesSolid,
    faClipboardCheck as faClipboardCheckSolid,
    faClipboardList as faClipboardListSolid,
} from "@fortawesome/pro-solid-svg-icons";

import {
    faChevronDown as faChevronDownRegular,
    faTimes as faTimesRegular,
} from "@fortawesome/pro-regular-svg-icons";

import {

} from "@fortawesome/pro-light-svg-icons";

import {
    faBars as faBarsDuotone,
    faUsers as faUsersDuotone,
    faQuestionSquare as faQuestionSquareDuotone,
    faBriefcase as faBriefcaseDuotone,
    faGifts as faGiftsDuotone,
    faUserCircle as faUserCircleDuotone,

    faSignIn as faSignInDuotone,
    faUserShield as faUserShieldDuotone,
    faUser as faUserDuotone,
    faSitemap as faSitemapDuotone,
    faGlobeAsia as faGlobeAsiaDuotone,
    faPhone as faPhoneDuotone,
    faFax as faFaxDuotone,
    faAt as faAtDuotone,
    faMapMarkerAlt as faMapMarkerAltDuotone,
    faStickyNote as faStickyNoteDuotone,

    faEye as faEyeDuotone,
    faTags as faTagsDuotone,
    faRulerCombined as faRulerCombinedDuotone,
    faInfoSquare as faInfoSquareDuotone,
    faHeading as faHeadingDuotone,
    faFileSearch as faFileSearchDuotone,
    faMoneyCheckEditAlt as faMoneyCheckEditAltDuotone,
    faCalendarAlt as faCalendarAltDuotone,
    faWarehouseAlt as faWarehouseAltDuotone,
    faCubes as faCubesDuotone,

    faThumbtack as faThumbtackDuotone,
    faHourglassHalf as faHourglassHalfDuotone,
    faStoreAlt as faStoreAltDuotone,
    faMap as faMapDuotone,

    faFileInvoiceDollar as faFileInvoiceDollarDuotone,
    faFlag as faFlagDuotone,
    faClock as faClockDuotone,
    faTasks as faTasksDuotone,
} from "@fortawesome/pro-duotone-svg-icons";

import {
    faFacebookSquare,
    faInstagram,
    faWhatsappSquare,
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faUserCogSolid,
    faPlusSquareSolid,
    faCaretDownSolid,
    faArrowLeftSolid,

    faEditSolid,
    faTrashAltSolid,
    faSpinnerSolid,
    faUserTieSolid,

    faUserHardHatSolid,
    faUploadSolid,
    faTasksSolid,
    faImageSolid,
    faFileAltSolid,
    faTimesSolid,
    faTagsSolid,
    faSaveSolid,
    faClipboardCheckSolid,
    faClipboardListSolid,

    faChevronDownRegular,
    faTimesRegular,

    faBarsDuotone,
    faUsersDuotone,
    faQuestionSquareDuotone,
    faBriefcaseDuotone,
    faGiftsDuotone,
    faUserCircleDuotone,

    faSignInDuotone,
    faUserShieldDuotone,
    faUserDuotone,
    faSitemapDuotone,
    faGlobeAsiaDuotone,
    faPhoneDuotone,
    faFaxDuotone,
    faAtDuotone,
    faMapMarkerAltDuotone,
    faStickyNoteDuotone,

    faEyeDuotone,
    faTagsDuotone,
    faRulerCombinedDuotone,
    faInfoSquareDuotone,
    faHeadingDuotone,
    faFileSearchDuotone,
    faMoneyCheckEditAltDuotone,
    faCalendarAltDuotone,
    faWarehouseAltDuotone,
    faCubesDuotone,

    faThumbtackDuotone,
    faHourglassHalfDuotone,
    faStoreAltDuotone,
    faMapDuotone,

    faFileInvoiceDollarDuotone,
    faFlagDuotone,
    faClockDuotone,
    faTasksDuotone,

    faFacebookSquare,
    faInstagram,
    faWhatsappSquare,
);
