import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import user from "./user.js";
import data from "./data.js";

export default new Vuex.Store({
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {

    },
    modules: {
        user, data,
    }
});
