<template>
    <div class="h-100 w-100">
        <nav-bar></nav-bar>
        <main class="main-view w-100">
            <transition name="slide-up">
                <router-view></router-view>
            </transition>
        </main>
    </div>
</template>

<script>
import NavBar from "../components/layout/NavBar";

export default {
    name: "Default",
    components: {
        NavBar,
    },
    data () {
        return {

        };
    },
    computed: {
        filteredProperties () {
            return [];
        },

        allowClickThrough () {
            return true;
        }
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.map-view {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
</style>
