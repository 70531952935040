<template>
    <b-modal size="md"
             centered
             hide-footer
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <template v-slot:modal-title>
            <strong>
                設定用戶密碼
            </strong>
        </template>

        <form v-on:submit.prevent="setPassword">
            <p class="text-justify">
                你好{{ name }}！再次歡迎你加入339到家智能自助服務平台。現在請設定你的用戶密碼以進行登入。
            </p>

            <div class="d-flex justify-content-center mb-3">
                <div class="d-flex align-items-center border rounded shadow-sm py-2 px-3">
                    <div class="mr-3">
                        <b-avatar size="4em"
                                  variant="light"
                                  v-bind:src="avatar">
                            <font-awesome-icon class="h-100 w-100 p-3"
                                               icon="user-tie"
                                               v-if="!avatar && ['admin', 'root', ].includes(role)">
                            </font-awesome-icon>
                            <font-awesome-icon class="h-100 w-100 p-3"
                                               icon="user-hard-hat"
                                               v-if="!avatar && role === 'vending-site-manager'">
                            </font-awesome-icon>
                        </b-avatar>
                    </div>
                    <div class="flex-grow-1">
                        <h4 class="font-weight-bold mb-1">
                            {{ name }}
                        </h4>
                        <p class="font-weight-normal mb-0">
                            {{ email }}
                        </p>
                    </div>
                </div>
            </div>

            <b-form-group label-for="password"
                          label="登入密碼">
                <b-form-input id="password"
                              type="password"
                              v-model="password">
                </b-form-input>
            </b-form-group>
            <b-form-group label-for="repeatPassword"
                          label="確認登入密碼">
                <b-form-input id="repeatPassword"
                              type="password"
                              v-model="repeatPassword">
                </b-form-input>
            </b-form-group>
            <div class="text-center">
                <button class="btn btn-light border px-4"
                        type="submit"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    提交
                </button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import { authToken, resetPassword, } from "../../workers/user.worker";
import { BModal, BFormGroup, BFormInput, BAvatar, } from "bootstrap-vue";
export default {
    name: "ModalSetPassword",
    components: {
        BModal, BFormGroup, BFormInput, BAvatar,
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },

        "token": {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,

            password: "",
            repeatPassword: "",

            user: null,
        };
    },
    computed: {
        name () {
            return this.user ? this.user.name : "";
        },

        email () {
            return this.user ? this.user.email : "";
        },

        role () {
            return this.user ? this.user.role : "";
        },

        avatar () {
            return this.user && this.user.avatar ?
                `${ process.env.VUE_APP_API_URL }/${ process.env.VUE_APP_API_VERSION }/user/avatar/${ this.user.avatar }` :
                "";
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },

        async setPassword () {
            this.isLoading = true;
            try {
                await resetPassword(
                    this.token,
                    this.password,
                )
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            this.$router.replace({
                "query": {
                    to: this.$route.query.to
                },
            });
            this.hideModal();
        },
    },
    async mounted () {
        if (this.token) {
            this.user = await authToken(this.token);
        }
    },
    watch: {
        async "token" (newVal) {
            if (newVal) {
                this.user = await authToken(this.token);
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
