<template>
    <b-modal size="md"
             centered
             hide-footer
             content-class="rounded-xl shadow-sm border-0"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <template v-slot:modal-title>
            <strong>
                My Account
            </strong>
        </template>

        <div class="pt-3 pb-2">
            <div class="text-center mb-2">
                <b-avatar variant="dark"
                          size="5.5rem"
                          v-bind:text="initials">
                </b-avatar>
            </div>
            <h3 class="font-weight-bold text-center mb-2">
                {{ name }}
            </h3>
            <div class="mb-4">
                <p class="content text-center mb-2">
                    <font-awesome-icon v-bind:icon="['fad', 'at']"></font-awesome-icon>
                    {{ email }}
                </p>
            </div>

            <div class="d-flex">
                <button class="btn btn-warning btn-block mr-2">
                    ⚙️ Update Account
                </button>
                <button class="btn btn-primary btn-block mt-0"
                        v-on:click="logout">
                    🚪 Logout
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import {
        BModal,
        BAvatar,
    } from "bootstrap-vue";

    export default {
        name: "ModalAccount",
        components: {
            BModal,
            BAvatar,
        },
        props: {
            "value": {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {

            };
        },
        computed: {
            user () {
                return this.$store.getters["user/info"];
            },
            name () {
                return this.user?.name ?? "";
            },
            initials () {
                const parts = this.name.split(" ");
                return parts.length > 1 ?
                    `${ parts[0][0] }.${ parts[1][0] }.` :
                    `${ parts[0][0] }${ parts[0][1] }`
            },
            email () {
                return this.user?.email ?? "";
            },
            userRole () {
                return this.$store.getters["user/role"];
            },
        },
        methods: {
            hideModal () {
                this.$emit("input", false);
            },
            async logout () {
                await this.$store.dispatch("user/logout");
                this.$router.push("/login");
                this.hideModal();
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
