import { render, staticRenderFns } from "./ModalAccount.vue?vue&type=template&id=db5a21ae&scoped=true&"
import script from "./ModalAccount.vue?vue&type=script&lang=js&"
export * from "./ModalAccount.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db5a21ae",
  null
  
)

export default component.exports